/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import "./order.css";
import "./orderDetails.css";
import url from "../../config/axios";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useState, useRef, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import blank from "../../assets/defaultImage.jpg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import newUser from "../../assets/newUser.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import ConfirmationOrderDialog from "../../components/ConfirmationOrder/ConfirmationOrderDialog";
import {
  AccessTimeFilledOutlined,
  CalendarMonthOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";

// const EOrderDeliveryStatus = {
//   PENDING: "PENDING",
//   DISPATCHED: "DISPATCHED",
//   DELIVERED: "DELIVERED",
//   CANCELLED: "CANCELLED",
// };

export default function OrderDetail() {
  const history = useHistory();
  const [order, setOrder] = useState({});
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [orderStatus, setOrderStatus] = useState(order.statusInfo?.statusID);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [deliveryStatus, setDeliveryStatus] = useState("PENDING");
  const [orderDetails, setOrderDetails] = useState("");
  const [disable, setDisable] = useState(
    order?.statusInfo?.statusID === "4" ? true : false
  );
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.success) {
      history.goBack();
    } else {
      ref.current.close();
    }
  };
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "Order Updated Successfully",
    heading: "Successfully Updated",
  });
  const [progress, setProgress] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    updateOrderDetails();
  }, []);

  const updateOrderDetails = () => {
    const orderID = new URLSearchParams(history.location.search).get("_id");
    url
      .get(`/v1/user-orders/order?_id=${orderID}`)
      .then((res) => {
        const parsedOrder = res?.data?.data?.order;
        setOrderStatuses(res?.data?.data?.statuses);
        setOrder(parsedOrder);
        setOrderStatus(parsedOrder?.statusInfo?.statusID);
        if (parsedOrder?.statusInfo?.statusID === "4") {
          setDisable(true);
        } else if (parsedOrder?.statusInfo?.statusID === "3") {
          setDisable(true);
        } else {
          setDisable(false);
        }
        setProgress(false);
      })
      .catch((e) => {
        setProgress(false);
      });
  };
  const steps = ["New", "Processing", "Complete"];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
      },
    },
    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "white",
        borderTop: "2px dotted #eaeaf0",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      borderWidth: 0.2,
      borderRadius: 1,
      backgroundColor: theme.palette.mode === "dark" ? " #FFA382" : "#eaeaf0",
      border: 0,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 15,
    height: 15,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#FFA382",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#FFA382",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}></ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const checkStatus = (status) => {
    setOrderStatus(status);
    // check order status sequence 1, 2, 3
    if (status === "2" && order?.statusInfo?.statusID === "3") {
      setDisable(true);
    } else if (status === "1" && order?.statusInfo?.statusID === "3") {
      setDisable(true);
    } else if (status === "2" && order?.statusInfo?.statusID === "2") {
      setDisable(true);
    } else if (status === "1" && order?.statusInfo?.statusID === "1") {
      setDisable(true);
    } else if (status === "3" && order?.statusInfo?.statusID === "3") {
      setDisable(true);
    } else if (status === "1" && order?.statusInfo?.statusID === "2") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const UpdateOrderDetails = () => {
    let orderDetails = {
      orderID: order._id,
      status: orderStatus,
      userID: order.userID,
    };

    setOrderDetails(orderDetails);
  };

  function getPaymentInfoString(consumerID, meta) {
    //go green using payfirma
    if (consumerID === "30") {
      return `Card Ending: ${
        meta?.paymentInfo?.paymentInfo?.Payments[0]?.Last4Digits ?? "Not Found"
      }`;
    }
    return null;
  }

  function convertTo12HourFormat(time24) {
    // Split the time string into hours and minutes
    let [hours, minutes] = time24.split(":").map(Number);

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // Convert '0' or '12' to '12' for 12-hour clock

    // Return formatted time string
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )} ${period}`;
  }

  // const UpdateDeliveryStatus = async () => {
  //   try {
  //     const body = {
  //       _id: order._id,
  //       status: deliveryStatus,
  //     };
  //     const res = await url.post(
  //       `/v1/user-orders/order/delivery/update-status`,
  //       body
  //     );
  //     setSuccess(true);
  //   } catch (error) {
  //     console.log("Failed to update delivery status", error);
  //     setSuccess(false);
  //   }
  // };
  return (
    <Fragment>
      {progress ? (
        "Loading..."
      ) : (
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12">
                <div className="card-new usr-m-b" id="orderDetaills">
                  <div className="row">
                    <div
                      style={{
                        height: "20px",
                        marginLeft: "0px",
                        backgroundColor: "#FFA382",
                        marginTop: "10px",
                      }}>
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          paddingTop: "4px",
                        }}>
                        {`Order ID: #${order?.orderID}`}
                      </div>
                    </div>

                    <div
                      className="col-md-7"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "40px",
                      }}>
                      <select
                        className="form-control"
                        style={{ width: "50%" }}
                        id="exampleFormControlSelect1"
                        value={orderStatus}
                        onChange={(e) => checkStatus(e.target.value)}
                        placeholder="Select Status"
                        defaultValue={"DEFAULT"}
                        disabled={
                          order?.statusInfo?.statusID === "4"
                            ? true
                            : order?.statusInfo?.statusID === "3"
                            ? true
                            : false
                        }>
                        <option>
                          {order?.statusInfo?.statusID === "4"
                            ? "Cancelled"
                            : order?.statusInfo?.statusID === "3"
                            ? order?.isDelivery
                              ? "Completed (Delivered)"
                              : "Completed"
                            : "Select Status"}
                        </option>
                        {orderStatuses &&
                          orderStatuses
                            .filter((status) => {
                              if (status.isSpecificToDelivery) {
                                return order.isDelivery;
                              }
                              return true;
                            })
                            .map((status) => {
                              return (
                                <>
                                  <option value={status?.statusID}>
                                    {status?.displayName}
                                  </option>
                                </>
                              );
                            })}
                      </select>
                      {/* {!order.isDelivery ? (
                        <select
                          className="form-control"
                          style={{ width: "50%" }}
                          id="exampleFormControlSelect1"
                          value={orderStatus}
                          onChange={(e) => checkStatus(e.target.value)}
                          placeholder="Select Status"
                          defaultValue={"DEFAULT"}
                          disabled={
                            order?.statusInfo?.statusID === "4"
                              ? true
                              : order?.statusInfo?.statusID === "3"
                              ? true
                              : false
                          }
                        >
                          <option>
                            {order?.statusInfo?.statusID === "4"
                              ? "Cancelled"
                              : order?.statusInfo?.statusID === "3"
                              ? "Completed"
                              : "Select Status"}
                          </option>
                          {orderStatuses &&
                            orderStatuses.map((status) => {
                              return (
                                <>
                                  <option value={status?.statusID}>
                                    {status?.displayName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      ) : (
                        <select
                          className="form-control"
                          style={{ width: "50%" }}
                          id="exampleFormControlSelect1"
                          placeholder="Select Delivery Status"
                          value={deliveryStatus}
                          onChange={(e) => setDeliveryStatus(e.target.value)}
                        >
                          {Object.keys(EOrderDeliveryStatus).map((key) => (
                            <option value={key}>
                              {EOrderDeliveryStatus[key]}
                            </option>
                          ))}
                        </select>
                      )} */}
                      <button
                        className="btn"
                        style={{
                          marginLeft: "15px",
                          marginRight: "-33px",
                          backgroundColor: "#FFA382",
                          color: "white",
                        }}
                        onClick={() => {
                          // if (order.isDelivery) {
                          //   UpdateDeliveryStatus();
                          // } else {
                          //   UpdateOrderDetails();
                          // }
                          UpdateOrderDetails();
                        }}
                        disabled={disable}>
                        Update
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      borderBottom: "2px solid #F1F5F9",
                    }}></div>
                  <div
                    className="card-history-user order-detail"
                    id="orderDetail">
                    {/* 3 boxes */}
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                      }}>
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "33.3%",
                          borderRadius: "5px",
                        }}>
                        <h6 style={{ marginTop: "8px" }}>Placed on</h6>
                        <p style={{ marginTop: "3px", fontSize: "12px" }}>
                          {moment(order.createdAt).format(
                            "D MMM YYYY,  h:mm A"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "180px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}>
                        <h6 style={{ marginTop: "8px" }}>Payment Status</h6>
                        <p style={{ fontSize: "14px" }}>
                          {order?.isPaymentApplicable ? (
                            <>
                              <b>
                                {order?.isPaymentCompleted
                                  ? "PAID"
                                  : "NOT PAID"}
                              </b>
                              <br />
                              {getPaymentInfoString(
                                order.consumerID,
                                order.meta
                              )}
                            </>
                          ) : (
                            <>N/A</>
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "33.3%",
                          borderRadius: "5px",
                        }}>
                        <h6 style={{ marginTop: "8px" }}>Seller</h6>
                        <p
                          style={{
                            fontSize: "12px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                          }}>
                          {admin[0].address}
                        </p>
                      </div>
                    </div>
                    <div class="border-top my-3"></div>
                    {order.lineItems.length >= 0 &&
                      order?.lineItems?.map((single) => {
                        const dealType = single?.targetDealInfo?.dealType;
                        const rootDealData = single?.targetDealInfo;
                        const dealData =
                          single?.targetDealInfo?.dealInfo[dealType] ?? {};
                        if (+single.quantity <= 0) {
                          return null;
                        }
                        return (
                          <div
                            className="table-mod custom-scrollbar order-data"
                            style={{ borderBottom: "2px solid #F1F5F9 " }}>
                            <div className="row-cust-ac ">
                              {dealType === "BOGO" &&
                              Object.values(dealData)?.length ? (
                                <Fragment>
                                  <div className="cust-row-ac-img">
                                    <img
                                      alt=""
                                      src={
                                        rootDealData?.images?.length
                                          ? rootDealData?.images[0]
                                          : blank
                                      }
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                  <div
                                    className="cust-row-ac-body"
                                    style={{ color: "#192746" }}>
                                    {rootDealData?.name}
                                  </div>
                                  <div className="cust-row-ac-desc">
                                    {/* buy product */}
                                    <span style={{ fontSize: "11px" }}>
                                      {single?.productInfo?.variant?.name ??
                                        single?.productInfo?.rootProduct
                                          ?.name}{" "}
                                      :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.purchaseQuantity}
                                        {" pc(s)"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                    {/* buy product */}
                                    <span style={{ fontSize: "11px" }}>
                                      {single?.addedProduct?.productInfo
                                        ?.variant?.name ??
                                        single?.addedProduct?.productInfo
                                          ?.rootProduct?.name}{" "}
                                      :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.addedProduct?.quantity}
                                        {" pc(s)"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                  </div>
                                  <div
                                    className="cust-row-ac-price"
                                    style={{ justifyContent: "right" }}>
                                    {parseInt(
                                      single?.purchaseQuantity /
                                        (dealData?.buyProduct?.exactQuantity ??
                                          1)
                                    )}{" "}
                                    x
                                    {single?.productInfo?.variant?.pricingInfo
                                      ?.currency === "CAD"
                                      ? "CA$"
                                      : "$"}
                                    {" " +
                                      parseFloat(
                                        (single?.finalPricingInfo
                                          ?.finalApplicableTotalPrice +
                                          single?.addedProduct?.finalPricingInfo
                                            ?.finalApplicableTotalPrice) /
                                          parseInt(
                                            single?.purchaseQuantity /
                                              (dealData?.buyProduct
                                                ?.exactQuantity ?? 1)
                                          )
                                      ).toFixed(2) +
                                      " "}
                                  </div>
                                </Fragment>
                              ) : null}
                              {dealType === "BOGO_BUNDLED" &&
                              Object.values(dealData)?.length ? (
                                <Fragment>
                                  <div className="cust-row-ac-img">
                                    <img
                                      alt=""
                                      src={
                                        rootDealData?.images?.length
                                          ? rootDealData?.images[0]
                                          : blank
                                      }
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                  <div
                                    className="cust-row-ac-body"
                                    style={{ color: "#192746" }}>
                                    {rootDealData?.name}
                                  </div>
                                  <div className="cust-row-ac-desc">
                                    {/* buy product */}
                                    <span style={{ fontSize: "11px" }}>
                                      {single?.productInfo?.variant?.name ??
                                        single?.productInfo?.rootProduct
                                          ?.name}{" "}
                                      :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.purchaseQuantity}
                                        {" pc(s)"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                    <Fragment>
                                        {(single.userPickedProducts?.length
                                          ? single.userPickedProducts
                                          : single?.addedProduct
                                          ? [single.addedProduct]
                                          : []
                                        ).map((addedProduct) => (
                                          <Fragment>
                                            <span style={{ fontSize: "11px" }}>
                                              {addedProduct?.productInfo
                                                ?.variant?.name ??
                                                addedProduct?.productInfo
                                                  ?.rootProduct?.name}{" "}
                                              :
                                              <span className="ac-green">
                                                {" "}
                                                {addedProduct?.quantity}
                                                {" pc(s)"}
                                              </span>
                                            </span>
                                            <br/>
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    <br /> <br />
                                  </div>
                                  <div
                                    className="cust-row-ac-price"
                                    style={{ justifyContent: "right" }}>
                                    {parseInt(
                                      single?.purchaseQuantity /
                                        (dealData?.buyProductExactQuantity ?? 1)
                                    )}{" "}
                                    x
                                    {single?.productInfo?.variant?.pricingInfo
                                      ?.currency === "CAD"
                                      ? "CA$"
                                      : "$"}
                                    {" " +
                                      parseFloat(
                                        (single?.finalPricingInfo
                                          ?.finalApplicableTotalPrice +

                                          (single?.userPickedProducts?.length ?
                                            single?.userPickedProducts.reduce((acc, item) => acc + item.finalPricingInfo.finalApplicableTotalPrice, 0) : 0)
                                          +
                                          (single?.addedProduct?.finalPricingInfo
                                            ?.finalApplicableTotalPrice ?? 0)) /
                                          parseInt(
                                            single?.purchaseQuantity /
                                              (dealData?.buyProductExactQuantity ??
                                                1)
                                          )
                                      ).toFixed(2) +
                                      " "}
                                  </div>
                                </Fragment>
                              ) : null}
                              {dealType === "REGULAR" &&
                              Object.values(dealData)?.length ? (
                                <Fragment>
                                  <div className="cust-row-ac-img">
                                    <img
                                      alt=""
                                      src={
                                        rootDealData?.images?.length
                                          ? rootDealData?.images[0]
                                          : blank
                                      }
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                  <div
                                    className="cust-row-ac-body"
                                    style={{ color: "#192746" }}>
                                    {rootDealData?.name}
                                  </div>
                                  <div className="cust-row-ac-desc">
                                    {/* buy product */}
                                    <span style={{ fontSize: "11px" }}>
                                      {single?.productInfo?.variant?.name ??
                                        single?.productInfo?.rootProduct
                                          ?.name}{" "}
                                      :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.purchaseQuantity}
                                        {" pc(s)"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                  </div>
                                  <div
                                    className="cust-row-ac-price"
                                    style={{ justifyContent: "right" }}>
                                    {single?.purchaseQuantity} x
                                    {single?.productInfo?.variant?.pricingInfo
                                      ?.currency === "CAD"
                                      ? "CA$"
                                      : "$"}
                                    {" " +
                                      parseFloat(
                                        single?.finalPricingInfo
                                          ?.finalApplicableSinglePrice
                                      ).toFixed(2) +
                                      " "}
                                  </div>
                                </Fragment>
                              ) : null}
                              {(dealType === "TIERED" ||
                                dealType === "TIERED_BUNDLED") &&
                              Object.values(dealData)?.length ? (
                                <Fragment>
                                  <div className="cust-row-ac-img">
                                    <img
                                      alt=""
                                      src={
                                        rootDealData?.images?.length
                                          ? rootDealData?.images[0]
                                          : blank
                                      }
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                  <div
                                    className="cust-row-ac-body"
                                    style={{ color: "#192746" }}>
                                    {rootDealData?.name}
                                  </div>
                                  <div className="cust-row-ac-desc">
                                    {/* buy product */}
                                    <span style={{ fontSize: "11px" }}>
                                      {single?.productInfo?.variant?.name ??
                                        single?.productInfo?.rootProduct
                                          ?.name}{" "}
                                      :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.purchaseQuantity}
                                        {" pc(s)"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                  </div>
                                  <div
                                    className="cust-row-ac-price"
                                    style={{ justifyContent: "right" }}>
                                    {single?.purchaseQuantity} x
                                    {single?.productInfo?.variant?.pricingInfo
                                      ?.currency === "CAD"
                                      ? "CA$"
                                      : "$"}
                                    {" " +
                                      parseFloat(
                                        single?.finalPricingInfo
                                          ?.finalApplicableSinglePrice
                                      ).toFixed(2) +
                                      " "}
                                  </div>
                                </Fragment>
                              ) : null}
                              {!dealType?.length ||
                              !Object.values(dealData)?.length ? (
                                <Fragment>
                                  <div className="cust-row-ac-img">
                                    <img
                                      alt=""
                                      src={
                                        single?.productInfo?.variant?.image ??
                                        single?.productInfo?.rootProduct
                                          ?.image ??
                                        blank
                                      }
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                  <div
                                    className="cust-row-ac-body"
                                    style={{ color: "#192746" }}>
                                    {single?.productInfo?.variant?.name ??
                                      single?.productInfo?.rootProduct?.name}
                                  </div>
                                  <div className="cust-row-ac-desc">
                                    <span style={{ fontSize: "11px" }}>
                                      Brand :
                                      <span className="ac-green">
                                        {" "}
                                        {single?.brandName ??
                                          single?.productInfo?.rootProduct
                                            ?.brandName ??
                                          "N/A"}
                                      </span>
                                    </span>
                                    <br /> <br />
                                  </div>
                                  <div
                                    className="cust-row-ac-price"
                                    style={{ justifyContent: "right" }}>
                                    {single?.purchaseQuantity ??
                                      single?.quantity}{" "}
                                    x
                                    {single?.productInfo?.variant?.pricingInfo
                                      ?.currency === "CAD"
                                      ? "CA$"
                                      : "$"}
                                    {" " +
                                      parseFloat(
                                        single.finalPricingInfo
                                          ?.finalApplicableSinglePrice
                                          ? single.finalPricingInfo
                                              ?.finalApplicableSinglePrice
                                          : 0
                                      ).toFixed(2) +
                                      " "}
                                  </div>
                                </Fragment>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    <br />
                    <div className="row">
                      <div className="col-lg-6"></div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between">
                          <span style={{ color: "#8C8C8C" }}>Subtotal</span>
                          <span style={{ color: "#8C8C8C" }}>
                            $&nbsp;
                            {parseFloat(order?.finalSubTotal).toFixed(2)}
                          </span>
                        </div>

                        {order?.chargesBreakDownHierarchy.map((x, index) => {
                          return (
                            <>
                              <div class="border-top my-3"></div>
                              <div className="d-flex justify-content-between">
                                <span
                                  className={
                                    index ===
                                    order?.chargesBreakDownHierarchy?.length - 1
                                      ? "text-[#00000]"
                                      : "text-[#8C8C8C]"
                                  }>
                                  {x?.chargeDisplayName === "Total" ? (
                                    <b>{x?.chargeDisplayName}</b>
                                  ) : (
                                    x?.chargeDisplayName
                                  )}
                                </span>
                                <span
                                  className={
                                    x?.chargeDisplayName === "Total"
                                      ? "text-[#00000]"
                                      : "text-[#8C8C8C]"
                                  }>
                                  {x?.chargedAmount < 0 ? "-" : ""}
                                  $&nbsp;
                                  {index ===
                                  order?.chargesBreakDownHierarchy?.length -
                                    1 ? (
                                    <b>
                                      {parseFloat(x?.chargedAmount).toFixed(2)}
                                    </b>
                                  ) : (
                                    parseFloat(
                                      Math.abs(x?.chargedAmount)
                                    ).toFixed(2)
                                  )}
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row">
              {order?.statusInfo?.statusID !== "4" && (
                <div className="col-md-12">
                  <div className="card-new usr-m-b">
                    <div className="">
                      <Stepper
                        alternativeLabel
                        activeStep={
                          orderStatus === "1" ? 0 : orderStatus === "2" ? 1 : 2
                        }
                        connector={<ColorlibConnector />}>
                        {orderStatuses.map((label) =>
                          label?.statusID !== "4" &&
                          order?.statusInfo?.statusID !== "4" ? (
                            <Step key={label}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {label?.displayName}
                              </StepLabel>
                            </Step>
                          ) : (
                            ""
                          )
                        )}
                      </Stepper>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="card-new usr-m-b" id="userBilling">
                  <div
                    className="row d-flex"
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      borderBottom: "2px solid rgba(241, 245, 249, 1)",
                      paddingBottom: "6px",
                    }}>
                    <div
                      style={{
                        height: "21px",
                        marginLeft: "-5px",
                        backgroundColor: "#FFA382",
                        marginTop: "8px",
                      }}>
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                        marginTop: "4px",
                        marginLeft: "5px",
                        color: "#FFA382",
                      }}>
                      Billing Details
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "8px",
                    }}>
                    {/*  */}
                    <div style={{ color: "#192746", paddingTop: "7px" }}>
                      <img
                        style={{ paddingRight: "15px", color: "#FFA382" }}
                        src={newUser}
                      />
                      {order?.userInfo?.name}
                    </div>
                    <div style={{ color: "#192746", paddingTop: "9px" }}>
                      <i
                        className="fa fa-phone-alt"
                        style={{ paddingRight: "15px", color: "#FFA382" }}></i>
                      {/* +1-245-698-2215 */}
                      {order?.userInfo?.mobileNo ?? "-"}
                    </div>
                    <div style={{ color: "#192746", paddingTop: "7px" }}>
                      <i
                        className="fa fa-envelope"
                        style={{ paddingRight: "15px", color: "#FFA382" }}></i>
                      {order.userInfo?.email}
                    </div>
                    {/*  */}
                  </div>
                </div>
                {order?.isDelivery && (
                  <div className="card-new usr-m-b" id="userBilling">
                    <div
                      className="row d-flex"
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        borderBottom: "2px solid rgba(241, 245, 249, 1)",
                        paddingBottom: "6px",
                      }}>
                      <div
                        style={{
                          height: "21px",
                          marginLeft: "-5px",
                          backgroundColor: "#FFA382",
                          marginTop: "8px",
                        }}>
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      <p
                        style={{
                          fontSize: "18px",
                          marginTop: "4px",
                          marginLeft: "5px",
                          color: "#FFA382",
                        }}>
                        Delivery Details
                      </p>
                    </div>

                    <div
                      style={{
                        marginTop: "8px",
                      }}>
                      <div
                        style={{
                          color: "#192746",
                          paddingTop: "9px",
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}>
                        <CalendarMonthOutlined color="primary" />
                        {/* +1-245-698-2215 */}
                        Date:{" "}
                        {order?.preferredDeliverySlot?.date
                          ? moment(order?.preferredDeliverySlot?.date).format(
                              "Do MMM, YYYY"
                            )
                          : "N/A"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "8px",
                      }}>
                      <div
                        style={{
                          color: "#192746",
                          paddingTop: "9px",
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}>
                        <AccessTimeFilledOutlined color="primary" />
                        {/* +1-245-698-2215 */}
                        Slot:{" "}
                        {order?.preferredDeliverySlot?.from &&
                        order?.preferredDeliverySlot?.to
                          ? `${convertTo12HourFormat(
                              order?.preferredDeliverySlot?.from
                            )} - ${convertTo12HourFormat(
                              order?.preferredDeliverySlot?.to
                            )}`
                          : "N/A"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "8px",
                      }}>
                      <div
                        style={{
                          color: "#192746",
                          paddingTop: "9px",
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}>
                        <LocationOnOutlined color="primary" />
                        {/* +1-245-698-2215 */}
                        Address:{" "}
                        {order.deliveryAddress
                          ? order.deliveryAddress.formatted_address
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <SimplePopup ref={ref} message={message} close={closeTooltip} /> */}

          {/* order confirmation popup */}
          {orderDetails && (
            <ConfirmationOrderDialog
              orderInfo={orderDetails}
              updateOrderDetails={updateOrderDetails}
              onDone={() => {
                setOrderDetails(null);
                setSuccess(true);
              }}
              onCancel={() => setOrderDetails(null)}
            />
          )}

          {success ? (
            <SimpleModal
              severity={"success"}
              highlights={"Order status updated successfully"}
              onClose={() => {
                setSuccess(false);
                history.goBack();
              }}
            />
          ) : null}
          {error ? (
            <SimpleModal
              severity={"error"}
              highlights={error}
              onClose={() => {
                setError(null);
              }}
            />
          ) : null}
        </div>
      )}
    </Fragment>
  );
}
