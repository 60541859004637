export const TypedDealsTypesSelectors = {
  REGULAR: {
    displayName: "REGULAR",
    shortDetails: "(discounts on selected products / categories / brands)",
    value: "REGULAR",
  },
  // BOGO: {
  //   displayName: "BOGO",
  //   shortDetails: "(buy X and get Y for Z)",
  //   isDeprecated: true,
  //   value: "BOGO",
  // },
  // TIERED: {
  //   displayName: "TIERED",
  //   shortDetails: "(buy at least $/Qty. worth of products and get $/% off)",
  //   isDeprecated: true,
  //   value: "TIERED",
  // },
  BOGO_BUNDLED: {
    displayName: "BOGO BUNDLED",
    shortDetails: "(buy X and get Y with the bundle)",
    value: "BOGO_BUNDLED",
  },
  TIERED_BUNDLED: {
    displayName: "TIERED BUNDLED",
    shortDetails:
      "(Buy at least $/Qty. worth of products from selected brands/categories/products and get $/% off)",
    value: "TIERED_BUNDLED",
  },
};

export const TypedDealsDaySelectors = {
  MON: {
    displayName: "Monday",
    value: "MON",
  },
  TUES: {
    displayName: "Tuesday",
    value: "TUES",
  },
  WED: {
    displayName: "Wednesday",
    value: "WED",
  },
  THURS: {
    displayName: "Thursday",
    value: "THURS",
  },
  FRI: {
    displayName: "Friday",
    value: "FRI",
  },
  SAT: {
    displayName: "Saturday",
    value: "SAT",
  },
  SUN: {
    displayName: "Sunday",
    value: "SUN",
  },
};

export const TypedDealsAmountTypeSelectors = {
  AMOUNT: {
    displayName: "Amount",
    indicator: "$",
    label: "Reduction By Dollar",
    value: "AMOUNT",
  },
  PERCENTAGE: {
    displayName: "Percentage",
    indicator: "%",
    label: "Reduction By Percentage",
    value: "PERCENTAGE",
  },
  //TO DO
  // FLAT: {
  //   displayName: "Flat Amount",
  //   indicator: "$",
  //   label: "Specific Amount",
  //   value: "FLAT",
  // },
};

export const TypedDealsBuyMinimumTypeSelectors = {
  AMOUNT: {
    displayName: "Amount",
    indicator: "$",
    value: "AMOUNT",
  },
  QUANTITY: {
    displayName: "Quantity",
    indicator: "Qty.",
    value: "QUANTITY",
  },
};

export const TypedRegularDealsAppliesToSelectors = {
  PRODUCTS: {
    value: "PRODUCTS",
    displayName: "Selected Products",
  },
  CATEGORIES: {
    value: "CATEGORIES",
    displayName: "Selected Categories",
  },
  BRANDS: {
    value: "BRANDS",
    displayName: "Selected Brands",
  },
};
export const TypedBogoBundledDealsAppliesToSelectors = {
  PRODUCTS: {
    value: "PRODUCTS",
    displayName: "Selected Products",
  },
  CATEGORIES: {
    value: "CATEGORIES",
    displayName: "Selected Categories",
  },
  BRANDS: {
    value: "BRANDS",
    displayName: "Selected Brands",
  },
};
export const TypedTieredBundledDealsAppliesToSelectors = {
  PRODUCTS: {
    value: "PRODUCTS",
    displayName: "Selected Products",
  },
  CATEGORIES: {
    value: "CATEGORIES",
    displayName: "Selected Categories",
  },
  BRANDS: {
    value: "BRANDS",
    displayName: "Selected Brands",
  },
};

export const TypedBogoBundledDealsExtendedProductPreferenceSelectors = {
  SELF: {
    value: "SELF",
    displayName: "The Same Product",
  },
  OTHER: {
    value: "OTHER",
    displayName: "Other Product",
  },
  ANY: {
    value: "ANY",
    displayName: "Product Of Users' Choice",
  },
};

export const BogoBundleTargetOffEntitySelectors = {
  ON_EXTENDED_PRODUCT: {
    value: "ON_EXTENDED_PRODUCT",
    displayName: "Faciliated Product Only",
  },
  ON_BUNDLE: { value: "ON_BUNDLE", displayName: "The Bundle" },
};
