import styled from "@emotion/styled";
import { AccountTree } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import SelectPrimary from "../../atoms/SelectPrimary";
import { BogoBundleTargetOffEntitySelectors, TypedBogoBundledDealsAppliesToSelectors, TypedBogoBundledDealsExtendedProductPreferenceSelectors, TypedDealsAmountTypeSelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { TypedBogoBundledDealsAppliesTo, TypedBogoBundledDeralsExtendedProductPreference, TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import { changeSinglePropertyAction, changeSinglePropertyActionUpdated, selectBogoBundledDealInfoState, selectBogoBundledTypeValidationErrors } from "../../redux/slices/addDealSlice";
import DropDownCategoryPicker from "../common/DropDownCategoryPicker";
import DropDownManufacturerPicker from "../common/DropDownManfacturerPicker";
import DropDownProductPicker from "../common/DropDownProductPicker";
import DropDownProductVariantPicker from "../common/DropDownProductVariantPicker";
import TypedDealsProductSelectionDialog from "./typed-deals-product-selection-tree";
import CustomSwitch from "../../atoms/Switch/CustomSwitch";
import { changeEditSinglePropertyAction, changeEditSinglePropertyActionUpdated, selectEditBogoBundledDealDefState, selectEditBogoBundledTypeValidationErrors } from "../../redux/slices/editDealDefSlice";

const BogoBundledDealFormComponent = ({ applyToAllOutlets, editMode }) => {
  const dispatch = useDispatch();
  const [treeViewOpen, setTreeViewOpen] = useState(false);
  const dealInfo = useSelector(editMode ? selectEditBogoBundledDealDefState : selectBogoBundledDealInfoState);
  const formValidationErrors = useSelector(editMode ? selectEditBogoBundledTypeValidationErrors : selectBogoBundledTypeValidationErrors);
  const { buyProductExactQuantity, selectedGetProduct, getProductExactQuantity, getProductVariant, getOffNumeric, getOffType, appliesTo, chosenCategories, chosenProducts, chosenBrands, extendedProductPreference, targetOffEntity, userPickedDiscount } = dealInfo;
  const [previousAppliesTo, setPreviousAppliesTo] = useState(appliesTo);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      if (editMode) {
        dispatch(
          changeEditSinglePropertyActionUpdated({
            dealType: TypedDealsTypes.BOGO_BUNDLED,
            propName,
            propValue,
          })
        );
      } else {
        dispatch(
          changeSinglePropertyActionUpdated({
            dealType: TypedDealsTypes.BOGO_BUNDLED,
            propName,
            propValue,
          })
        );
      }
    }
  };


  useEffect(
    () => {
      if (!editMode) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
        handleChange("selectedGetProduct", {});
      }
    },
    //eslint-disable-next-line
    [applyToAllOutlets]
  );
  useEffect(
    () => {
      if (appliesTo !== previousAppliesTo) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
      }
      setPreviousAppliesTo(appliesTo);
    },
    //eslint-disable-next-line
    [appliesTo, previousAppliesTo]
  );
  const [prevSelectedGetProduct, setPrevSelectedGetProduct] = useState(selectedGetProduct);
  useEffect(
    () => {
      if (prevSelectedGetProduct !== selectedGetProduct) {
        if (selectedGetProduct && Object.values(selectedGetProduct).length === 1) {
          const value = Object.values(selectedGetProduct)[0];
          if (value.variants.length === 1) {
            const defaultVariant = value.variants[0];
            const id = defaultVariant?.tempProductID ?? defaultVariant.productID;
            handleChange("getProductVariant", { [id]: defaultVariant });
          } else {
            handleChange("getProductVariant", {});
          }
        } else {
          handleChange("getProductVariant", {});
        }
        setPrevSelectedGetProduct(selectedGetProduct);
      }
    },
    //eslint-disable-next-line
    [selectedGetProduct, prevSelectedGetProduct]
  );
  return (
    <Fragment>
      <DealLabelWrapper>
        <label>BOGO BUNDLED RULES</label>
      </DealLabelWrapper>
      {/* buy product */}
      <ChooserWrapper style={{ zIndex: 1200, marginBottom: 16 }}>
        <div className="col-md-4">
          <InputPrimary value={buyProductExactQuantity} label={"Buy *"} type="number" name="buyProductExactQuantity" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          <span className="validation-help">{formValidationErrors?.buyProductExactQuantity ? formValidationErrors.buyProductExactQuantity : ""}</span>
        </div>
        <div className="col-md-4">
          <SelectPrimary value={appliesTo} label={"Applies To *"} name="appliesTo" onChange={(e) => handleChange(e.target.name, e.target.value)}>
            {Object.values(TypedBogoBundledDealsAppliesToSelectors).map((item, index) => (
              <option value={item.value} key={`selected-bogo-bundled-applies-to-${index}`}>
                {`${item.displayName}`}
              </option>
            ))}
          </SelectPrimary>
        </div>
        <div
          className="col-md-4"
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "90%",
            }}
          >
            {appliesTo === TypedBogoBundledDealsAppliesTo.CATEGORIES ? <DropDownCategoryPicker label={"Pick Categories*"} selected={chosenCategories} setSelected={(value) => handleChange("chosenCategories", value)} referLocalData={applyToAllOutlets ? false : true} /> : null}
            {appliesTo === TypedBogoBundledDealsAppliesTo.PRODUCTS ? <DropDownProductPicker label={"Pick Products"} selected={chosenProducts} setSelected={(value) => handleChange("chosenProducts", value)} referLocalInventory={applyToAllOutlets ? false : true} /> : null}
            {appliesTo === TypedBogoBundledDealsAppliesTo.BRANDS ? <DropDownManufacturerPicker label={"Pick Brands"} selected={chosenBrands} setSelected={(value) => handleChange("chosenBrands", value)} referLocalData={applyToAllOutlets ? false : true} /> : null}

            <span className="validation-help">{formValidationErrors?.appliesTo ? formValidationErrors.appliesTo : ""}</span>
          </div>
          <div
            style={{
              display: "flex",
              width: "auto",
              marginLeft: "10px",
              marginTop: "35px",
            }}
          >
            <Tooltip title="View Product Selection Tree">
              <AccountTree
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTreeViewOpen(true);
                }}
              />
            </Tooltip>
          </div>
        </div>
        {treeViewOpen ? <TypedDealsProductSelectionDialog editMode={editMode} dealType={TypedDealsTypes.BOGO_BUNDLED} dealSelector={editMode ? selectEditBogoBundledDealDefState : selectBogoBundledDealInfoState} onClose={() => setTreeViewOpen(false)} /> : null}
      </ChooserWrapper>

      {/* get product */}
      <ChooserWrapper style={{ marginBottom: "4em" }}>
        <div className="col-md-4">
          <InputPrimary value={getProductExactQuantity} label={"Get *"} type="number" name="getProductExactQuantity" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          <span className="validation-help">{formValidationErrors?.getProductExactQuantity ? formValidationErrors.getProductExactQuantity : ""}</span>
        </div>
        <div className="col-md-4">
          <SelectPrimary value={extendedProductPreference} label={"Get Product Type"} name="extendedProductPreference" onChange={(e) => handleChange(e.target.name, e.target.value)}>
            {Object.values(TypedBogoBundledDealsExtendedProductPreferenceSelectors).map((item, index) => (
              <option value={item.value} key={`selected-bogo-bundled-applies-to-${index}`}>
                {`${item.displayName}`}
              </option>
            ))}
          </SelectPrimary>
        </div>
        <div className="col-md-4"></div>
      </ChooserWrapper>
      {/* faciliated product */}
      {extendedProductPreference === TypedBogoBundledDeralsExtendedProductPreference.OTHER ? (
        <ChooserWrapper style={{ zIndex: 1100 }}>
          <div className="col-md-4">
            <DropDownProductPicker isSingleSelect={true} label={"Select Faciliated Product"} inputLabel={"Select Product"} selected={selectedGetProduct} setSelected={(value) => handleChange("selectedGetProduct", value)} referLocalInventory={applyToAllOutlets ? false : true} />
            <span className="validation-help">{formValidationErrors?.selectedGetProduct ? formValidationErrors.selectedGetProduct : ""}</span>
          </div>
          {selectedGetProduct && Object.values(selectedGetProduct).length > 0 ? (
            <div className="col-md-4">
              {Object.values(selectedGetProduct)[0]?.variants?.length > 1 ? (
                <Fragment>
                  <DropDownProductVariantPicker blankLabel={true} isSingleSelect={true} product={Object.values(selectedGetProduct)[0]} inputLabel={"Select Variant"} selected={getProductVariant} setSelected={(value) => handleChange("getProductVariant", value)} />
                  <span className="validation-help">{formValidationErrors?.getProductVariant ? formValidationErrors.getProductVariant : ""}</span>
                </Fragment>
              ) : null}
            </div>
          ) : null}
        </ChooserWrapper>
      ) : null}
      {/* discount */}
      {extendedProductPreference !== "ANY" ? (
        <>
          <DealLabelWrapper>
            <label>Discount Details</label>
          </DealLabelWrapper>
          {getOffType === "AMOUNT" && (
            <DiscountTypeHint>
              <InfoIcon /> From the products buyer gets free from the deal, a set amount of money will be deducted from each product regardless of the price. i.e if discount amount is $30 and product price is $70, buyer will get the product for (70 - 30) = $40. If after reduction the product price becomes negative, user will get the product for free
            </DiscountTypeHint>
          )}
          {getOffType === "PERCENTAGE" && (
            <DiscountTypeHint>
              <InfoIcon /> From the products buyer gets free from the deal, the specified percentage from product price will be deducted. i.e if discount amount is 25% and product price is $100, buyer will get the product for ($100 - $100 * 0.25) = $75. To Offer full free product, user 100% as the percentage value
            </DiscountTypeHint>
          )}
          {getOffType === "FLAT" && (
            <DiscountTypeHint>
              <InfoIcon /> From the products buyer gets free from the deal, the product will be offered at flat price specified here. i.e. if the product costs $1000 and selected flat price is $10, the product will be offered at $10. To giveaway the product, use $0 as flat price
            </DiscountTypeHint>
          )}
          <ChooserWrapper style={{ zIndex: 999 }}>
            <div className="col-md-4">
              <SelectPrimary label={"Price Reduction Type *"} value={getOffType} name="getOffType" onChange={(e) => handleChange(e.target.name, e.target.value)}>
                {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
                  <option value={item.value} key={`selected-deal-amount-type-${index}`}>
                    {item.label ?? `${item.indicator} Off`}
                  </option>
                ))}
              </SelectPrimary>
            </div>

            <div className="col-md-4">
              <InputPrimary value={getOffNumeric} label={"Amount *"} type="number" name="getOffNumeric" onChange={(e) => handleChange(e.target.name, e.target.value)} hintChip={getOffType === "PERCENTAGE" ? "%" : "$"} />
              <span className="validation-help">{formValidationErrors?.getOffNumeric ? formValidationErrors.getOffNumeric : ""}</span>
            </div>

            <div className="col-md-4">
              <SelectPrimary value={targetOffEntity} label={"On"} name="targetOffEntity" onChange={(e) => handleChange(e.target.name, e.target.value)}>
                {Object.values(BogoBundleTargetOffEntitySelectors).map((item, index) => (
                  <option value={item.value} key={`selected-bogo-bundled-applies-to-${index}`}>
                    {`${item.displayName}`}
                  </option>
                ))}
              </SelectPrimary>
            </div>
          </ChooserWrapper>
        </>
      ) : (
        <>
          <DealLabelWrapper>
            <label>User Pick Constraincts</label>
          </DealLabelWrapper>

          <DiscountTypeHint>
            <InfoIcon /> Setup constraints on user picked products like max price, category.
          </DiscountTypeHint>
          <ChooserWrapper style={{ zIndex: 999 }}>
            <div className="col-md-4">
              <SelectPrimary label={"Category *"} value={getOffType} name="getOffType" onChange={(e) => handleChange(e.target.name, e.target.value)}>
                {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
                  <option value={item.value} key={`selected-deal-amount-type-${index}`}>
                    {item.label ?? `${item.indicator} Off`}
                  </option>
                ))}
              </SelectPrimary>
            </div>

            <div className="col-md-4">
              <InputPrimary value={getOffNumeric} label={"Amount *"} type="number" name="getOffNumeric" onChange={(e) => handleChange(e.target.name, e.target.value)} hintChip={getOffType === "PERCENTAGE" ? "%" : "$"} />
              <span className="validation-help">{formValidationErrors?.getOffNumeric ? formValidationErrors.getOffNumeric : ""}</span>
            </div>

            <div className="col-md-4">
              <SelectPrimary value={targetOffEntity} label={"On"} name="targetOffEntity" onChange={(e) => handleChange(e.target.name, e.target.value)}>
                {Object.values(BogoBundleTargetOffEntitySelectors).map((item, index) => (
                  <option value={item.value} key={`selected-bogo-bundled-applies-to-${index}`}>
                    {`${item.displayName}`}
                  </option>
                ))}
              </SelectPrimary>
            </div>
          </ChooserWrapper>
          <ChooserWrapper style={{ marginBottom: "4em", zIndex: 1000 }}>
            <div className="col-md-6">
              {userPickedDiscount.maxAmountConstraint && <InputPrimary 
                value={userPickedDiscount.maxAmount} 
                label={"Max amount *"} 
                type="number" 
                name="userPickedDiscount.maxAmount" 
                onChange={(e) => handleChange(e.target.name, e.target.value)} hintChip={"$"} 
              />}
              {/* <span className="validation-help">{formValidationErrors?.getOffNumeric ? formValidationErrors.getOffNumeric : ""}</span> */}
              <CustomSwitch variant="ios" label="Set Max amount of user picked product" value={userPickedDiscount.maxAmountConstraint ? true : false} onChange={(value) => { console.log(value); handleChange("userPickedDiscount.maxAmountConstraint", value) }} />
            </div>
            <div className="col-md-6">
              <DropDownCategoryPicker label={"Pick Categories*"} selected={userPickedDiscount.categories} setSelected={(value) => handleChange("userPickedDiscount.categories", value)}  referLocalData={applyToAllOutlets ? false : true} />
            </div>
          </ChooserWrapper>
        </>
      )}
    </Fragment>
  );
};
export default BogoBundledDealFormComponent;

const DealLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;

const ChooserWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1.5em;
  position: relative;
  transform: translateX(-14px);
`;

const DiscountTypeHint = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${(props) => props.theme.palette.secondary.main}28;
  padding: 8px 12px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  color: 1px solid ${(props) => props.theme.palette.secondary.main};
`;
